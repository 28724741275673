import OccurrenceTypeRepository from '@/shared/http/repositories/socialProject/occurrence-type'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegister from '@/components/layout/NewRegister/newRegister.vue'
import OccurrenceType from '@/shared/models/occurrenceType'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'EditarTipoDeOcorrência',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegister.name]: NewRegister
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Tipo de Ocorrência',
    occurrenceType: new OccurrenceType(),
    isNew: false
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Tipos de Ocorrência',
            href: this.$router.resolve({ name: 'OccurrenceType' }).href
          },
          {
            text: 'Editar Tipo de Ocorrência',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getById()
  },

  methods: {
    getById() {
      loading.push()
      OccurrenceTypeRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.occurrenceType = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipo de ocorrência', 'ERRO')
        })
    },

    update(occurrenceType) {
      loading.push()
      OccurrenceTypeRepository.Update(occurrenceType)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Tipo de Ocorrência editado com sucesso', 'EDITAR TIPO DE OCORRÊNCIA')
          Promise.resolve()
          return this.$router.replace({ name: 'OccurrenceType' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar tipo de ocorrência', 'ERRO')
        })
    }
  }
}
